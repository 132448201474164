<template>
  <a-drawer
    :title="model.id>0?'编辑':'添加'"
    :maskClosable="false"
    width="600"
    placement="right"
    :closable="true"
    @close="hide()"
    :visible="isShow"
  >

    <a-form :form="form" style="margin-left: 30px">
      <a-form-item label='反馈类型' v-bind="formItemLayout">
        <a-input  v-model="model.name"/>
      </a-form-item>
      <a-form-item label='排序' v-bind="formItemLayout">
        <a-input v-model="model.sort"/>
      </a-form-item>
    </a-form>

    <div class="drawer-bootom-button">
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}
export default {
  name: 'FeedbackTypeEdit',
  data () {
    return {
      isShow: false,
      formItemLayout,
      model: {
        id:0,
        name:"",
        sort:"",
      },
      form: this.$form.createForm(this),
      loading: false
    }
  },
  methods: {

    hide () {
      this.form.resetFields()
      this.isShow = false
    },
    show (model = {}) {
      this.isShow = true
      this.model = JSON.parse(JSON.stringify(model))
    },
    handleSubmit () {
      if(this.model.id>0){
        this.edit()
      }else{
        this.add()
      }

    },
    add(){
      this.loading = true
      this.$post('feedback_type', {
        ...this.model
      }).then((r) => {
        this.loading = false
        this.isShow = false
        this.hide()
        this.$emit('success')
      }).catch((err)=>{
        this.loading = false
      })
    },
    edit(){
      this.loading = true
      this.$put('feedback_type', {
        ...this.model
      }).then((r) => {
        this.loading = false
        this.isShow = false
        this.hide()
        this.$emit('success')
      }).catch((err)=>{
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
